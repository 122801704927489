import React from "react"
import { graphql } from "gatsby"
import Layout from "~/components/layout"
import ProductList from "~/components/product-list"
import SEO from "~/components/seo"

const ProductIndexPage = ({
  data: {
    allStrapiProduct: { edges },
  },
}) => {
  const flatProducts = edges.map(({ node }) => node)

  const seo = { title: "精选优质产品 优惠价购买" }

  return (
    <Layout logoUseH1={true}>
      <SEO seo={seo} />
      <ProductList products={flatProducts} />
    </Layout>
  )
}

export const ProductIndexPageQuery = graphql`query ProductSearchQuery {
  allStrapiProduct(sort: {fields: created_at, order: DESC}) {
    edges {
      node {
        name
        slug
        price
        originalPrice
        sold
        ImageDesc {
          desc
          image {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 360
                layout: CONSTRAINED
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP]
                quality: 75
              )
            }
          }
        }
      }
    }
  }
}
`

export default ProductIndexPage
